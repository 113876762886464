.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
}
.image {
    width: 80%; 
    height: 80%;
    position: relative; 
}
.image img {
    width: 100%; 
    height: 80%; 
    object-fit: cover; 
    position: absolute; 
    top: 0; 
    right: 0; 
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    margin-top: -20px;
}
.image img:hover {
    transform: translate(50%, 50%) scale(2.1);
}